<template>
  <b-card
    class="top-level"
  >
    <b-form>
      <b-row align-v="end">

        <b-col
          class="px-2"
          cols="12"
          md="8"
        >
          <span
            class="text-nowrap flex-parent my-0"
          >
            <h2 v-if="discount.code">{{ discount.code }}</h2>

          </span>
        </b-col>
        <b-col
          cols="12"
          md="4"
          align="right"
          class="py-1"
        >
          <b-row
            align-v="end"
          >
            <b-col
              cols="12"
              md="6"
              class="mb-1 mb-md-0"
            >
              <b-button
                class="bg-white"
                variant="outline-success"
                :disabled="state === 'loading'"
                @click="saveDiscount(false)"
              >
                {{ discount.id ? 'Gravar e continuar editando' : 'Adicionar cupom e continuar editando' }}
              </b-button>

            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-button
                variant="success"
                :disabled="state === 'loading'"
                @click="saveDiscount(true)"
              >
                {{ discount.id ? 'Gravar' : 'Adicionar cupom' }}
              </b-button>

            </b-col>
          </b-row>

        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-actions
            title="Desconto"
            action-collapse
          >
            <b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Código"
                  label-for="codigo"
                >
                  <b-form-input
                    id="codigo"
                    v-model="discount.code"
                    :state="discountValidation.code.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ discountValidation.code.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Percentual (0-100)"
                  label-for="value_percent"
                >
                  <b-form-input
                    id="value_percent"
                    v-model="discount.value_percent"
                    type="number"
                    :state="discountValidation.value_percent.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ discountValidation.value_percent.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Valor em R$"
                  label-for="value_abs"
                >
                  <b-form-input
                    id="value_abs"
                    v-model="discount.value_abs"
                    type="number"
                    :state="discountValidation.value_abs.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ discountValidation.value_abs.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex mb-1">
                  <b-form-checkbox
                    v-model="discount.free_delivery"
                    name="q6-switch"
                    switch
                  />
                  <p
                    class="mb-0"
                  >
                    Frete grátis
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="E-mail"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="discount.email"
                    :state="discountValidation.email.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ discountValidation.email.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row><b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Início validade  (no início do dia às 00:00)"
                  label-for="start-date-date"
                >
                  <b-form-datepicker
                    id="start-date-date"
                    v-model="discount.start_dateDate"
                    locale="pt-BR"
                    :state="discountValidation.start_date.state"
                    :disabled="state === 'loading'"
                    class="mb-2"
                    placeholder="Data"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
                  />

                  <b-form-invalid-feedback>
                    {{ discountValidation.start_date.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Fim Validade (no final do dia às 23:59)"
                  label-for="validity-date"
                >
                  <b-form-datepicker
                    id="validity-date"
                    v-model="discount.validityDate"
                    locale="pt-BR"
                    :state="discountValidation.validity.state"
                    :disabled="state === 'loading'"
                    class="mb-2"
                    placeholder="Data"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
                  />

                  <b-form-invalid-feedback>
                    {{ discountValidation.validity.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Quantidade"
                  label-for="availability"
                >
                  <b-form-input
                    id="availability"
                    v-model="discount.availability"
                    :state="discountValidation.availability.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ discountValidation.availability.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="px-2"
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Valor mínimo"
                  label-for="min_value"
                >
                  <b-form-input
                    id="min_value"
                    v-model="discount.min_value"
                    type="number"
                    :state="discountValidation.min_value.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ discountValidation.min_value.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

          </b-card-actions>
        </b-col>
      </b-row>

      <b-row v-if="(discount.orders && discount.orders.length > 0)">
        <b-col>
          <b-card-actions
            title="Pedidos"
            action-collapse
          >

            <b-row
              v-for="order in discount.orders"
              :key="order.id"
              align-v="center"
              class="pb-1"
            >
              <b-col
                cols="12"
              >
                <span
                  class="text-nowrap flex-parent"
                >
                  {{ order.identification }}.&nbsp;
                  <span class="flex-child long-and-truncated">{{ order.client_full_name }}</span>
                </span>
              </b-col>
            </b-row>

          </b-card-actions>
        </b-col>
      </b-row>

    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BMedia, BFormInvalidFeedback, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import md5 from 'md5'
import axios from 'axios'

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BMedia,
    BFormInvalidFeedback,
    BFormDatepicker,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    discountId: {
      type: Number,
      required: false,
      default: '-1',
    },
  },
  data() {
    return {

      state: 'ready',
      dir: false,
      validation: false,

      discount: {
        id: null,
        code: '',
        value_percent: null,
        value_abs: null,
        free_delivery: false,
        payment_method: null,
        email: null,
        validityDate: null,
        start_dateDate: null,
        availability: 1,
        available: false,
        value: null,
        min_value: null,
      },
      validationBase: {
        status: {
          state: null,
          feedback: '',
        },
        discount_date: {
          state: null,
          feedback: '',
        },
        delivery: {
          state: null,
          feedback: '',
        },
        identification: {
          state: null,
          feedback: '',
        },
        frete_provider: {
          state: null,
          feedback: '',
        },
        frete_tracking_number: {
          state: null,
          feedback: '',
        },
        nfe_number: {
          state: null,
          feedback: '',
        },
        product_choices: {
          state: null,
          feedback: '',
        },
        client: {
          full_name: {
            state: null,
            feedback: '',
          },
          cpf_cnpj: {
            state: null,
            feedback: '',
          },
          cep: {
            state: null,
            feedback: '',
          },
          address: {
            state: null,
            feedback: '',
          },
          number: {
            state: null,
            feedback: '',
          },
          complement: {
            state: null,
            feedback: '',
          },
          neighbourhood: {
            state: null,
            feedback: '',
          },
          city: {
            state: null,
            feedback: '',
          },
          state: {
            state: null,
            feedback: '',
          },
          phone: {
            state: null,
            feedback: '',
          },
          email: {
            state: null,
            feedback: '',
          },
          additional_info: {
            state: null,
            feedback: '',
          },
        },
      },
      discountValidation: {
        code: {
          state: null,
          feedback: '',
        },
        value_percent: {
          state: null,
          feedback: '',
        },
        value_abs: {
          state: null,
          feedback: '',
        },
        free_delivery: {
          state: null,
          feedback: '',
        },
        payment_method: {
          state: null,
          feedback: '',
        },
        email: {
          state: null,
          feedback: '',
        },
        validity: {
          state: null,
          feedback: '',
        },
        start_date: {
          state: null,
          feedback: '',
        },
        min_value: {
          state: null,
          feedback: '',
        },
        availability: {
          state: null,
          feedback: '',
        },
      },
    }
  },
  computed: {
    validity() {
      return `${this.discount.validityDate}T23:59:59-03:00`
    },
    start_date() {
      return `${this.discount.start_dateDate}T00:00:00-03:00`
    },
  },
  watch: {
  },
  created() {
    if (this.discountId !== '-1') {
      this.state = 'loading'
      this.$store.dispatch('discounts/getDiscountById', this.discountId).then(() => {
        this.discount = this.$store.state.discounts.discountData
        this.discount.validityDate = this.discount.validity ? this.discount.validity.split('T')[0] : null
        this.discount.start_dateDate = this.discount.start_date ? this.discount.start_date.split('T')[0] : null
      }).finally(() => {
        this.state = 'ready'
      })
    }
  },
  methods: {
    saveDiscount(goToDiscountsList) {
      this.discount.validity = this.validity
      this.discount.start_date = this.start_date
      Object.keys(this.discount).forEach(k => this.discount[k] = this.discount[k] === '' ? null : this.discount[k])
      this.$store.dispatch('discounts/createOrUpdateDiscount', this.discount).then(() => {
        if (goToDiscountsList) {
          this.$router.push('/discounts')
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Desconto',
            icon: 'SuccessIcon',
            variant: 'success',
            text: 'Desconto gravado com sucesso!',
          },
        })
      }).catch(error => {
        console.log(error)
        // this.orderValidation = JSON.parse(JSON.stringify(this.validationBase))
        /* eslint-disable */
          // for (const [k, v] of Object.entries(error.response.data)) {
          //   if (Array.isArray(v)) {
          //     this.orderValidation[k] = {
          //       state: false,
          //       feedback: v[0],
          //     }
          //   } else {
          //     for (const [k2, v2] of Object.entries(v)) {
          //       this.orderValidation[k][k2] = {
          //         state: false,
          //         feedback: v2[0],
          //       }
          //     }
          //   }
          // }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Desconto',
              icon: 'DangerIcon',
              variant: 'danger',
              text: 'Não conseguimos gravar este desconto. Por favor verificar.',
            },
          })
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
